:root {
  --base-color: #bfbfbf;
  --secondary-color: #358b8a;
}
html {
  scroll-behavior: smooth;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: transparent;
}

/* Track */

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--base-color);
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--secondary-color);
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hidescrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidescrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* 
.base-input {
  height: 36px;
  min-width: 580px;
  background-color: #fff;
} */

@media print {
  .pagebreak {
    clear: both;
    page-break-after: always;
  }
  @page :first {
    margin-top: 0; /* No margin on the first page */
  }

  @page {
    margin-top: 10mm; /* Adjust the margin-top value as needed */
  }
}
