.active {
  color: #358b8a;
  display: block;
  font-weight: 500;
  fill: #358b8a;
  text-decoration: none;
  /* border-right: 4px solid #358b8a; */
}

.faqActive {
  color: #358b8a;
  display: block;
  font-weight: 500;
  fill: #358b8a;
  text-decoration: none;
}

.inactive {
  display: block;
  font-weight: 500;
  color: #959595;
  fill: #959595;
  text-decoration: none;
}
